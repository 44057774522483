@import '~app/mixins';

button {
  cursor: pointer;
}

.header {
  display: flex;
  padding: 9px 21px 9px 7px;
  min-height: 65px;
  align-items: flex-end;

  .subject {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-right: 50px;

    .address {
      font-size: 15px;
      color: $color-blue-dark;
      white-space: pre-wrap;
    }

    .details {
      margin-top: 3px;
      display: flex;
      font-size: 12px;

      .detail {
        color: $color-grey;
        padding: 0 5px;

        &:first-child {
          padding-left: 0;
        }

        + .detail {
          border-left: 1px solid $color-grey-light;
        }
      }
    }
  }

  .savedAnalyses {
    margin-left: 30px;
    width: 300px;
  }

  button {
    margin-left: 30px;
  }
}

.body {
  display: flex;
  padding: 5px;
  border-top: 2px solid $color-grey-light;
  border-bottom: 2px solid $color-grey-light;
  height: calc(100vh - 160px);

  > div {
    border: 1px solid $color-grey-light;
    border-radius: 4px;

    &.left {
      flex-grow: 1;
      margin-right: 5px;

      .fieldContainer {
        display: flex;
        justify-content: center;

        .fields {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 75px;
          position: relative;

          .info {
            position: absolute;
            right: 0;
            bottom: 13px;
            width: 240px;
            border: 1px solid $color-grey-light;
            color: $color-grey-light;
            background-color: $color-grey-lighter;
            border-radius: 5px;
            margin-right: -260px;
            font-size: 11px;
            padding: 4px;
            cursor: pointer;
          }

          .profit {
            color: $color-grey;

            span {
              padding-left: 10px;
              font-weight: bold;
            }
          }

          .field {
            margin: 0 15px;

            > div {
              text-align: center;
            }

            input {
              max-width: 100px;
            }
          }
        }
      }

      .bottom {
        overflow-y: auto;
        width: 1060px;
        border-top: 1px solid $color-grey-light;
        padding-top: 5px;
        height: calc(100vh - 301px);

        .surrounding {
          height: 100%;
          overflow: hidden;

          > div {
            transform: scale(0.8);
            transform-origin: top left;
            width: 1345px;
            height: 128%;
          }
        }

        .logo {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          img {
            width: 95%;
            opacity: 0.3;
          }
        }
      }
    }

    &.right {
      width: 277px;
      background-color: $color-dirty-white;

      .title {
        color: $color-blue-darkest;
        text-align: center;
        margin: 15px;
        font-size: 16px;
      }

      .items {
        overflow-y: auto;

        .row {
          justify-content: space-between;

          .key {
            color: $color-blue-darkest;
            font-size: 12px;
            line-height: 20px;
            width: auto;
          }

          .value {
            color: $color-grey;
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;
            width: auto;
          }
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 12px 20px 0;

  .left {
    button {
      min-width: 100px;

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  .right {
    button {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
